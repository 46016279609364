/* ========================================================================
 * # Main JS file
 *    Docs:
 *    http://api.jquery.com/
 *    https://github.com/AllThingsSmitty/jquery-tips-everyone-should-know
 *    https://github.com/peterkokot/awesome-jquery
 ========================================================================*/

(function ($) {
    function mobileStructureChange() {
        var $el;
        var $inner = $('#primaryNavBar');
        var $innerDefault = $('.header-inner');
        if ($(window).width() < 992) {
            $el = $('.header__cta-block').detach();
            $inner.append($el);
            $el.addClass('cta-mobile');
        }
        if ($(window).width() > 991 && $inner.has('.header__cta-block')) {
            $el = $('.header__cta-block').detach();
            $innerDefault.append($el);
            $el.removeClass('cta-mobile');
        }
    }
    $.fn.stickyTabs = function( options ) {
        var context = this;

        var settings = $.extend({
            getHashCallback: function(hash, btn) { return hash; }
        }, options );

        // Show the tab corresponding with the hash in the URL, or the first tab.
        var showTabFromHash = function() {
            var hash = window.location.hash;
            var selector = hash ? 'a[href="' + hash + '"]' : 'li.active > a';
            $(selector, context).tab('show');
            window.scroll(0,0);
        };

        // We use pushState if it's available so the page won't jump, otherwise a shim.
        var changeHash = function(hash) {
            if (history && history.pushState) {
                history.pushState(null, null, '#' + hash);
            } else {
                var scrollV = document.body.scrollTop;
                var scrollH = document.body.scrollLeft;
                window.location.hash = hash;
                document.body.scrollTop = scrollV;
                document.body.scrollLeft = scrollH;
            }
        };

        // Set the correct tab when the page loads
        showTabFromHash(context);

        // Set the correct tab when a user uses their back/forward button
        $(window).on('hashchange', showTabFromHash);

        // Change the URL when tabs are clicked
        /*$('a', context).on('click', function(e) {
            var hash = this.href.split('#')[1];
            var adjustedhash = settings.getHashCallback(hash, this);
            changeHash(adjustedhash);
        });*/


        // Change the URL when links on the page are clicked
        $('a').on('click', function(e) {
            var hash = this.href.split('#')[1];
            var adjustedhash = settings.getHashCallback(hash, this);

            if ( $(this).hasClass('rob-tabs__toggle') ) {
                changeHash(adjustedhash);
            }
            else {
                var selector = context.find('a[href="#'+hash+'"]');

                if(hash && (selector.length>0)) {
                    $(selector, context).tab('show');
                    var adjustedhash = settings.getHashCallback(hash, this);

                    if (history && history.pushState) {
                        history.pushState(null, null, '#' + hash);
                    }

                    var scrollV = context.offset().top;
                    window.location.hash = adjustedhash;
                    document.body.scrollTop = scrollV;
                }
            }
        });

        return this;
    };
    function fixTabLinks() {
        var $tabs = $('.nav-tabs');

        if ($tabs.length > 0) {
            $tabs.stickyTabs();
        }
    }


    function detectURL() {
        if ( window.location.hash ) {
            function Scrolldown() {
                window.scroll(0,0);
            }
            Scrolldown();

            $(document).ready(function () {
                Scrolldown();
            });

            $(window).on('load', function(){
                Scrolldown();

                var $tabLinkToOpen = $('.nav-tabs li a[href="' + window.location.hash + '"]');

                if ( $tabLinkToOpen && $tabLinkToOpen.length ) {
                    var $tabsBlock = $tabLinkToOpen.closest('.nav-tabs');

                    $tabLinkToOpen.tab('show');

                    if ($tabsBlock.length > 0) {
                        $("html, body").animate({scrollTop:$tabsBlock.offset().top}, 700, 'swing');
                    }
                }
                else {
                    var $scrollTarget = $(window.location.hash);

                    if ($scrollTarget && $scrollTarget.length) {
                        $("html, body").animate({scrollTop:$scrollTarget.offset().top}, 700, 'swing');
                    }
                }
            });
        }
    }


    detectURL();
    // Scripts that will only run once the page Document Object Model (DOM) is ready for JavaScript code to execute.
    $(document).ready(function () {
        $(".hamburger").click(function () {
            $(this).toggleClass("is-active");
        });

        $('button.navbar-toggler').on('click', function (event) {
            event.preventDefault();
            $('body').toggleClass('mobile-menu-is-open');
            $('.nav-primary').toggleClass('show');
        })

        mobileStructureChange();

        //init tabs nav
        fixTabLinks();

        $(document).on('click', 'a', function (event) {
            var hash = this.href.split('#')[1];
            var locationURL = location.origin + location.pathname + location.search;
            var url = this.href.substr(0, this.href.indexOf("#"));

            if ( hash && ((url === locationURL) || (url === '')) ) {
                event.preventDefault();

                var $target = $('body').find('#'+hash);
                var $tabSelector = $('body').find('a.rob-tabs__toggle[href="#'+hash+'"]');

                if ($tabSelector && $tabSelector.length) {
                    $('html, body').animate({
                        scrollTop: $tabSelector.closest('.nav-tabs').offset().top
                    }, 500);
                }
                else if ($target.length > 0) {
                    $('html, body').animate({
                        scrollTop: $target.offset().top
                    }, 500);
                }
            }
        });
        var $navs = $('.rob-tabs__nav-tab');
        var $blocks = $('.tab-pane');
        $navs.each(function () {
           $(this).on('click','a',function () {
               $navs.removeClass('active').removeClass('show');
               $(this).parent().addClass('active show');
               var $el = $(this).attr('aria-controls');
               $blocks.removeClass('active').removeClass('show');
               $('#'+$el).addClass('active show');
           });
        });
        var $goToTab = $(location).attr('hash');
        var $tabElement = $('.rob-tabs__nav-tab a[href="' + $goToTab + '"]');
        if ($tabElement.length) {
            $tabElement.click();
            console.log($goToTab);
        }
        ///////FILTER
        const filters = $('.filter-list').find('a');
        const filter = $('#filter');
        var formDropdown = filter.find('select');

        filters.each(function (index) {

            var currFilter = $(this);

            currFilter.on('click', function (e) {
                e.preventDefault();
                formDropdown.val(currFilter.data('id')).trigger('change');

                $.ajax({
                    url: filter.attr('action'),
                    data: filter.serialize(),
                    type: filter.attr('method'),

                    success: function (data) {
                        var $data = $(data);
                        filters.not(currFilter).removeClass('active');
                        currFilter.addClass('active');
                        $('.car-archive')
                            .html($data)
                    }
                });
                return false;
            });
        });

        /*Sliders*/
        $('.slider-for').slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: false,
            fade: true,
            asNavFor: '.slider-nav'
        });
        $('.slider-nav').slick({
            slidesToShow: 4,
            slidesToScroll: 1,
            asNavFor: '.slider-for',
            dots: false,
            centerMode: false,
            focusOnSelect: true,
            arrows: false,
            responsive: [
                {
                    breakpoint: 520,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                        dots: true,
                        arrows: false
                    }
                }
            ]
        });
        $('.logo-slider-inner').slick({
            arrows: true,
            dots: false,
            draggable: true,
            swipe: true,
            autoplay: true,
            autoplaySpeed: 5000,
            infinite: true,
            slidesToShow: 6,
            slidesToScroll: 1,
            cssEase: 'linear',
            responsive: [
                {
                    breakpoint: 1080,
                    settings: {
                        slidesToShow: 4,
                        slidesToScroll: 1,
                        infinite: true,
                    }
                }, {
                    breakpoint: 991,
                    settings: {
                        slidesToShow: 3,
                        autoplaySpeed: 4000,
                        slidesToScroll: 1
                    }
                }, {
                    breakpoint: 720,
                    settings: {
                        slidesToShow: 2,
                        autoplaySpeed: 4000,
                        slidesToScroll: 1
                    }
                }
            ]
        });

        function new_map($el) {

            // var
            var $markers = $el.find('.marker');


            // vars
            var args = {
                zoom: 16,
                center: new google.maps.LatLng(0, 0),
                mapTypeId: google.maps.MapTypeId.ROADMAP,
                styles: [
                    {
                        "elementType": "geometry",
                        "stylers": [
                            {
                                "color": "#5882a9"
                            }
                        ]
                    },
                    {
                        "elementType": "labels",
                        "stylers": [
                            {
                                "color": "#5c5c5c"
                            }
                        ]
                    },
                    {
                        "elementType": "labels.icon",
                        "stylers": [
                            {
                                "visibility": "off"
                            }
                        ]
                    },
                    {
                        "elementType": "labels.text.stroke",
                        "stylers": [
                            {
                                "color": "#999999"
                            },
                            {
                                "weight": 0.5
                            }
                        ]
                    },
                    {
                        "featureType": "landscape",
                        "elementType": "geometry",
                        "stylers": [
                            {
                                "saturation": 30
                            },
                            {
                                "lightness": 30
                            }
                        ]
                    },
                    {
                        "featureType": "poi",
                        "elementType": "geometry",
                        "stylers": [
                            {
                                "saturation": 20
                            }
                        ]
                    },
                    {
                        "featureType": "poi.park",
                        "elementType": "geometry",
                        "stylers": [
                            {
                                "saturation": -20
                            },
                            {
                                "lightness": 20
                            }
                        ]
                    },
                    {
                        "featureType": "road",
                        "elementType": "geometry",
                        "stylers": [
                            {
                                "saturation": -30
                            },
                            {
                                "lightness": 10
                            }
                        ]
                    },
                    {
                        "featureType": "road",
                        "elementType": "geometry.fill",
                        "stylers": [
                            {
                                "color": "#85c8fe"
                            },
                            {
                                "saturation": -15
                            }
                        ]
                    },
                    {
                        "featureType": "road",
                        "elementType": "geometry.stroke",
                        "stylers": [
                            {
                                "saturation": 25
                            },
                            {
                                "lightness": 25
                            }
                        ]
                    },
                    {
                        "featureType": "road.highway",
                        "elementType": "geometry.fill",
                        "stylers": [
                            {
                                "color": "#8cd7ff"
                            },
                            {
                                "saturation": -5
                            }
                        ]
                    },
                    {
                        "featureType": "water",
                        "stylers": [
                            {
                                "lightness": -20
                            }
                        ]
                    }
                ]
            };

            // create map
            var map = new google.maps.Map($el[0], args);

            // add a markers reference
            map.markers = [];

            // add markers
            $markers.each(function () {

                add_marker($(this), map);

            });

            // center map
            center_map(map);

            // return
            return map;

        }

        //This function will add a marker to the selected Google Map
        function add_marker($marker, map) {
            var latlng = new google.maps.LatLng($marker.attr('data-lat'), $marker.attr('data-lng'));
            var marker = new google.maps.Marker({
                position: latlng,
                map: map,
                icon: marker_path
                //icon: 'http://sto.onzenieuwe.website/wp-content/uploads/2018/07/marker.png'
            });
            map.markers.push(marker);
            if ($marker.html()) {
                var infowindow = new google.maps.InfoWindow({
                    content: $marker.html()
                });

                function openWindow() {
                    infowindow.open(map, marker);
                }

                google.maps.event.addListener(map, 'click', function () {
                    infowindow.close();
                });
                google.maps.event.addListener(marker, 'click', function () {
                    new google.maps.event.trigger(map, 'click');
                    setTimeout(function () {
                        openWindow()
                    }, 200);
                });
            }
        }


        // center_map

        function center_map(map) {

            // vars
            var bounds = new google.maps.LatLngBounds();

            // loop through all markers and create bounds
            $.each(map.markers, function (i, marker) {

                var latlng = new google.maps.LatLng(marker.position.lat(), marker.position.lng());

                bounds.extend(latlng);

            });

            // only 1 marker?
            if (map.markers.length == 1) {
                // set center of map
                map.setCenter(bounds.getCenter());
                map.setZoom(16);
            }
            else {
                // fit to bounds
                map.fitBounds(bounds);
            }

        }

        //document ready
        // global var
        var map = null;

        $(document).ready(function () {

            $('.acf-map').each(function () {

                // create map
                map = new_map($(this));

            });

        });
    });

// Scripts that will run after the whole page is loaded (images, videos, iframes. etc)
    $(window).on('load', function () {

    });

// Scripts that will run on window resize
    $(window).on('resize', function () {
        mobileStructureChange();
    });

})
(jQuery); // Fully reference jQuery after this point.
